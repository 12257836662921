import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import translation from "images/svg_icons/translation.svg";
import ip from "images/svg_icons/lamp.svg";
import compliance from "images/svg_icons/check-box.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Localization Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="Legal Compliance"
          href="/services/localization/compliance/"
          align="verticalreverse"
          content={
            <p>
              Though Chinese law is changing constantly, our Beijing-based team
              always stays on top of the current rules & regulations so you
              don't have to.
            </p>
          }
          img={compliance}
        />
        <Card
          title="Chinese Translation"
          href="/services/localization/translation/"
          content={
            <p>
              AppInChina provides translation services to all our clients as
              part of our comprehensive publication and distribution package.
            </p>
          }
          img={translation}
        />
        <Card
          title="Intellectual Property Protection"
          href="/services/localization/intellectual-property/"
          content={
            <p>
              We make it easy to register for a Software Copyright Certificate,
              trademark and copyright protection, ICP, ISBN/GRN, and more
            </p>
          }
          img={ip}
        />
      </div>
    </Layout>
  </div>
);
