import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/location-mark.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import LocalizationGrid from "components/services/LocalizationGrid";

export default () => (
  <>
    <Meta url="/services/localization/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Localization"
      black="Services"
      text="Our international, English-speaking team knows how to protect your interests in China - registering your copyrights while staying compliant with local laws."
    />
    <ImageBlock
      title="Enter China with confidence."
      text={
        <>
          <p>
            Forget what you've heard about doing business in China. Your games
            and apps will enjoy robust legal protections when you are in full
            compliance with local laws.
          </p>
          <p>
            AppInChina does the hard work for you: registering your copyright
            and trademark; acquiring ISBN/GRN and ICP licenses; and translating
            your app or game into Mandarin.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/localization.png"
          alt="Rich Bishop"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <LocalizationGrid />
    <CTA />
    <Footer />
  </>
);
